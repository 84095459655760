import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import AdminRoute from './AdminRoute';

import { LoginPage } from 'pages/Login';
import { NotFoundPage } from 'pages/NotFound';

// ADMIN: Clients
import { ClientList } from 'pages/AdminPage/Clients/List';
import { ClientCreatePage } from 'pages/AdminPage/Clients/Client/Create Client';
import { ClientPage } from 'pages/AdminPage/Clients/Client';
import { BucketList } from 'pages/AdminPage/Clients/Client/Categories/Buckets/List';
import { BucketAdd } from 'pages/AdminPage/Clients/Client/Categories/Buckets/Add';
import { BucketUpdate } from 'pages/AdminPage/Clients/Client/Categories/Buckets/Edit';
import { ReportPage } from 'pages/AdminPage/Clients/Client/Reports/Generate';

// ADMIN: MAILER
import { MailerPage } from 'pages/AdminPage/Clients/Client/Mailer/Mailer';
import { GeneratePage } from 'pages/AdminPage/Clients/Client/Mailer/Generate';

// ADMIN: Corpus
import { CorpusClientPage } from 'pages/AdminPage/Corpus/CorpusClient';
import { CorpusKeywordPage } from 'pages/AdminPage/Corpus/CorpusKeyword';
import { CorpusMonitorPage } from 'pages/AdminPage/Corpus/CorpusMonitor';

// ADMIN: MAILER MONITORING
import { MailerMonitoringPage } from 'pages/AdminPage/Mailer Monitoring';
// ADMIN: RSS
import { RSSPage } from 'pages/AdminPage/RSS';

// ADMIN: MAILER TEMPLATES
import { MailerTemplatePage } from 'pages/AdminPage/MailerTemplates/MailerTemplate';
import { MailerTemplatesList } from 'pages/AdminPage/MailerTemplates/List';

// AUTHORS
import { AuthorList } from 'pages/Authors/List';
import { AuthorPage } from 'pages/Authors/Author';
import { AuthorProfile } from 'pages/Authors/Profile';

//MONITORING
import { AVMonitoringList } from 'pages/Monitoring/List';
import { KeywordClientList } from 'pages/Monitoring/Keywords/List';
import { ReportsMonitoringPage } from 'pages/AdminPage/Reports';
import { ActivityTrackerList } from 'pages/Monitoring/ActivityTracker/List';

// ONLINE NEWS
import { UrlListPage } from 'pages/Online/List';
import { OnlineNewsPage } from 'pages/Online/OnlineNews';

// PRINT
import { PrintDownloadPage } from 'pages/Print/Download';
import { EditPrintPage } from 'pages/Print/EditPrint';
import { PrintMediaPage } from 'pages/Print/PrintMedia';
import { PrintAdsList } from 'pages/Print/PrintAds/List';
import { PrintAdPage } from 'pages/Print/PrintAds/PrintAd';

// PUBLICATIONS
import { Publication, PublicationList, PublicationListProfile } from 'pages/Publications';

// RADIO
import { RadioAdsPage } from 'pages/Radio/RadioAds/List';
import { RadioClipPage } from 'pages/Radio/RadioClips/RadioClip';
import { RadioClipList } from 'pages/Radio/RadioClips/List';
// new add
// RADIO PROGRAM SETUP
import { RadioProgramSetupPage } from 'pages/Radio/ProgramSetup/ProgramSetup';
import { RadioProgramSetupList } from 'pages/Radio/ProgramSetup/List';

// PROGRAM SETUP
import { ProgramSetupPage } from 'pages/TV/ProgramSetup/ProgramSetup';
import { ProgramSetupList } from 'pages/TV/ProgramSetup/List';

// TV
import { TvAdsPage } from 'pages/TV/TvAds/List';
import { MonitoringList } from 'pages/TV/Monitoring/List';
import { VideoClipList } from 'pages/TV/VideoClips/List';
import { VideoClipPage } from 'pages/TV/VideoClips/VideoClip';

// WEBSITE GARDEN
import { WebsiteGardenPage } from 'pages/WebsiteGarden/List';
import AddWebsiteContainer from 'pages/WebsiteGarden/AddWebsite/AddWebsiteContainer';
import { InfluencersListPage } from 'pages/Influencers/List';
import AddInfluencerContainer from 'pages/Influencers/AddInfluencer/AddInfluencerContainer';
import { TvProgramList } from 'pages/TV/ProgramList/List';
import { RadioProgramList } from 'pages/Radio/ProgramList/List';
import { UsersListPage } from 'pages/AdminPage/Users/List';
import { PostsListPage } from 'pages/SocialMedia/Posts/List';
import { AddPostPage } from 'pages/SocialMedia/Posts/Posts';
import { MailerViewerPage } from 'pages/Mailer';
import { TimeSlotList } from 'pages/TV/TimeSlot/List';
import { RadioTimeSlotList } from 'pages/Radio/Timeslot/List';
import { ControlList } from 'pages/TV/Control/List';
import { TvAdsCreatePage } from 'pages/TV/TvAds/TVAds';
import { RadioAdsCreatePage } from 'pages/Radio/RadioAds/RadioAds';

const Routes: React.FC = () => {
  const role: string =
    localStorage.getItem('role') === undefined || localStorage.getItem('role') === null
      ? ''
      : JSON.parse(localStorage.getItem('role') || '');

  return (
    <Switch>
      <Route exact path={ROUTES.LOGIN} component={LoginPage} />

      <Route path={ROUTES.MAILER} component={MailerViewerPage} />

      {/* ADMIN: Clients */}
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.LIST} component={ClientList} access="access_clients" />
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.CREATE} component={ClientCreatePage} access="access_clients" />
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.CATEGORY} component={ClientPage} access="access_clients" />
      <AdminRoute
        exact
        path={ROUTES.ADMIN.CLIENTS.BUCKETS.LIST}
        component={BucketList}
        access="access_categories_buckets"
      />

      <AdminRoute
        exact
        path={ROUTES.ADMIN.CLIENTS.BUCKETS.ADD}
        component={BucketAdd}
        access="access_categories_buckets"
      />
      <AdminRoute
        exact
        path={ROUTES.ADMIN.CLIENTS.BUCKETS.UPDATE}
        component={BucketUpdate}
        access="access_categories_buckets"
      />
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.DETAILS} component={ClientPage} access="access_client_users" />
      <AdminRoute exact path={ROUTES.API_FEED.OVERVIEW.OVERVIEW} component={RSSPage} access="access_clients" />

      {/* ADMIN: Mailer */}
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.MAILER.LIST} component={ClientPage} access="access_mailer_setup" />
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.MAILER.CREATE} component={MailerPage} access="access_mailer_setup" />
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.MAILER.EDIT} component={MailerPage} access="access_mailer_setup" />
      <AdminRoute
        exact
        path={ROUTES.ADMIN.CLIENTS.MAILER.GENERATE}
        component={GeneratePage}
        access="access_mailer_setup"
      />

      {/* ADMIN: CLIENT REPORTS  */}
      <AdminRoute exact path={ROUTES.ADMIN.CLIENTS.REPORTS.LIST} component={ClientPage} access="access_mailer_setup" />
      <AdminRoute
        exact
        path={ROUTES.ADMIN.CLIENTS.REPORTS.GENERATE}
        component={ReportPage}
        access="access_mailer_setup"
      />

      {/* ADMIN: Mailer Templates */}
      <AdminRoute
        exact
        path={ROUTES.ADMIN.MAILER.TEMPLATES.LIST}
        component={MailerTemplatesList}
        access="access_mailer_setup"
      />
      <AdminRoute
        exact
        path={ROUTES.ADMIN.MAILER.TEMPLATES.CREATE}
        component={MailerTemplatePage}
        access="access_mailer_setup"
      />
      <AdminRoute
        exact
        path={ROUTES.ADMIN.MAILER.TEMPLATES.EDIT}
        component={MailerTemplatePage}
        access="access_mailer_setup"
      />

      {/* ADMIN: Corpus */}
      <AdminRoute exact path={ROUTES.ADMIN.CORPUS.CLIENT} component={CorpusClientPage} access="access_corpus" />
      <AdminRoute exact path={ROUTES.ADMIN.CORPUS.KEYWORD} component={CorpusKeywordPage} access="access_corpus" />

      {/* ADMIN: USERS */}
      <AdminRoute
        access={role !== 'Operations' && role !== 'BD/CS' ? 'access_admin_users_management' : ''}
        exact
        path={ROUTES.ADMIN.USERS.LIST}
        component={UsersListPage}
      />

      {/* PUBLICATIONS */}
      <AdminRoute exact path={ROUTES.PUBLICATIONS.LIST.LIST} component={PublicationList} access="access_publishers" />
      <AdminRoute
        exact
        path={ROUTES.PUBLICATIONS.LIST.PROFILE}
        component={PublicationListProfile}
        access="access_publishers"
      />
      <AdminRoute exact path={ROUTES.PUBLICATIONS.LIST.CREATE} component={Publication} access="access_publishers" />
      <AdminRoute exact path={ROUTES.PUBLICATIONS.LIST.EDIT} component={Publication} access="access_publishers" />

      {/* AUTHORS */}
      <AdminRoute exact path={ROUTES.AUTHORS.OVERVIEW.LIST} component={AuthorList} access="access_authors" />
      <AdminRoute exact path={ROUTES.AUTHORS.OVERVIEW.PROFILE} component={AuthorProfile} access="access_authors" />
      <AdminRoute exact path={ROUTES.AUTHORS.OVERVIEW.CREATE} component={AuthorPage} access="access_authors" />
      <AdminRoute exact path={ROUTES.AUTHORS.OVERVIEW.EDIT} component={AuthorPage} access="access_authors" />

      {/* ONLINE */}
      <AdminRoute exact path={ROUTES.ONLINE.LIST} component={UrlListPage} access="access_online_news_blogs" />
      <AdminRoute exact path={ROUTES.ONLINE.CREATE} component={OnlineNewsPage} access="access_online_news_blogs" />
      <AdminRoute exact path={ROUTES.ONLINE.EDIT} component={OnlineNewsPage} access="access_online_news_blogs" />

      {/* PRINT */}
      <AdminRoute exact path={ROUTES.PRINT.DOWNLOAD} component={PrintDownloadPage} access="access_print" />
      <AdminRoute exact path={ROUTES.PRINT.PRINTMEDIA.LIST} component={PrintMediaPage} access="access_print" />
      <AdminRoute exact path={ROUTES.PRINT.PRINTMEDIA.VERIFY} component={EditPrintPage} access="access_print" />
      <AdminRoute exact path={ROUTES.PRINT.PRINTMEDIA.EDIT} component={EditPrintPage} access="access_print" />
      <AdminRoute exact path={ROUTES.PRINT.EDIT} component={EditPrintPage} access="access_print" />
      <AdminRoute exact path={ROUTES.PRINT.ADS.LIST} component={PrintAdsList} access="access_print" />
      <AdminRoute exact path={ROUTES.PRINT.ADS.EDIT} component={PrintAdPage} access="access_print" />
      <AdminRoute exact path={ROUTES.PRINT.ADS.VERIFY} component={PrintAdPage} access="access_print" />

      {/* TV: Control */}
      <AdminRoute exact path={ROUTES.TV.CONTROL.LIST} component={ControlList} access="access_tv" />

      {/* TV: Channel Timeslot */}
      <AdminRoute exact path={ROUTES.TV.TIMESLOT.LIST} component={TimeSlotList} access="access_tv" />

      {/* TV: Program Setup */}
      <AdminRoute
        exact
        path={ROUTES.TV.PROGRAMSETUP.LIST}
        component={ProgramSetupList}
        access="access_automation_tv_setup"
      />
      <AdminRoute
        exact
        path={ROUTES.TV.PROGRAMSETUP.CREATE}
        component={ProgramSetupPage}
        access="access_automation_tv_setup"
      />
      <AdminRoute
        exact
        path={ROUTES.TV.PROGRAMSETUP.EDIT}
        component={ProgramSetupPage}
        access="access_automation_tv_setup"
      />

      {/* TV: Program List */}
      <AdminRoute exact path={ROUTES.TV.PROGRAMLIST.LIST} component={TvProgramList} access="access_tv_program" />

      {/* TV: Video Clips */}
      <AdminRoute exact path={ROUTES.TV.TVCLIPS.LIST} component={VideoClipList} access="access_tv" />
      <AdminRoute exact path={ROUTES.TV.TVCLIPS.CREATE} component={VideoClipPage} access="access_tv" />
      <AdminRoute exact path={ROUTES.TV.TVCLIPS.EDIT} component={VideoClipPage} access="access_tv" />
      <AdminRoute exact path={ROUTES.TV.TVADS.LIST} component={TvAdsPage} access="access_tv" />
      <AdminRoute exact path={ROUTES.TV.TVADS.CREATE} component={TvAdsCreatePage} access="access_tv" />
      <AdminRoute exact path={ROUTES.TV.TVADS.EDIT} component={TvAdsCreatePage} access="access_tv" />

      {/* RADIO: Channel Timeslot */}
      {/* new add */}

      <AdminRoute
        exact
        path={ROUTES.RADIO.PROGRAMSETUP.LIST}
        component={RadioProgramSetupList}
        access="access_automation_tv_setup"
      />
      <AdminRoute
        exact
        path={ROUTES.RADIO.PROGRAMSETUP.CREATE}
        component={RadioProgramSetupPage}
        access="access_automation_tv_setup"
      />
      <AdminRoute
        exact
        path={ROUTES.RADIO.PROGRAMSETUP.EDIT}
        component={RadioProgramSetupPage}
        access="access_automation_tv_setup"
      />
      <AdminRoute exact path={ROUTES.RADIO.TIMESLOT.LIST} component={RadioTimeSlotList} access="access_radio" />

      {/* RADIO: Program List */}
      <AdminRoute exact path={ROUTES.RADIO.PROGRAMLIST.LIST} component={RadioProgramList} access="access_radio" />

      {/* RADIO: Radio Clips */}
      <AdminRoute exact path={ROUTES.RADIO.RADIOCLIPS.LIST} component={RadioClipList} access="access_radio" />
      <AdminRoute exact path={ROUTES.RADIO.RADIOCLIPS.CREATE} component={RadioClipPage} access="access_radio" />
      <AdminRoute exact path={ROUTES.RADIO.RADIOCLIPS.EDIT} component={RadioClipPage} access="access_radio" />

      <AdminRoute exact path={ROUTES.RADIO.RADIOAD.LIST} component={RadioAdsPage} access="access_radio" />
      <AdminRoute exact path={ROUTES.RADIO.RADIOAD.CREATE} component={RadioAdsCreatePage} access="access_radio" />
      <AdminRoute exact path={ROUTES.RADIO.RADIOAD.EDIT} component={RadioAdsCreatePage} access="access_radio" />

      {/* Website Garden */}
      <AdminRoute
        access="access_management_of_website_garden"
        exact
        path={ROUTES.WEBSITEGARDEN.LIST.LIST}
        component={WebsiteGardenPage}
      />
      <AdminRoute
        exact
        path={ROUTES.WEBSITEGARDEN.LIST.CREATE}
        component={AddWebsiteContainer}
        access="access_management_of_website_garden"
      />
      <AdminRoute
        exact
        path={ROUTES.WEBSITEGARDEN.LIST.EDIT}
        component={AddWebsiteContainer}
        access="access_management_of_website_garden"
      />

      {/* Influencers */}
      <AdminRoute
        exact
        path={ROUTES.INFLUENCERS.LIST.LIST}
        component={InfluencersListPage}
        access="access_influencers"
      />
      <AdminRoute
        exact
        path={ROUTES.INFLUENCERS.LIST.CREATE}
        component={AddInfluencerContainer}
        access="access_influencers"
      />
      <AdminRoute
        exact
        path={ROUTES.INFLUENCERS.LIST.EDIT}
        component={AddInfluencerContainer}
        access="access_influencers"
      />

      {/* Social Media */}
      <AdminRoute exact path={ROUTES.SOCIALMEDIA.POSTS.LIST} component={PostsListPage} access="access_social_media" />
      <AdminRoute exact path={ROUTES.SOCIALMEDIA.POSTS.CREATE} component={AddPostPage} access="access_social_media" />
      <AdminRoute exact path={ROUTES.SOCIALMEDIA.POSTS.EDIT} component={AddPostPage} access="access_social_media" />

      {/* Monitoring */}
      <AdminRoute
        exact
        path={ROUTES.MONITORING.CORPUS}
        component={CorpusMonitorPage}
        access="access_mailer_monitoring"
      />
      <AdminRoute
        exact
        path={ROUTES.MONITORING.MAILER}
        component={MailerMonitoringPage}
        access="access_mailer_monitoring"
      />
      <AdminRoute
        exact
        path={ROUTES.MONITORING.FB_DOWNLOADER.LIST}
        component={MonitoringList}
        access="access_fb_download_monitoring"
      />
      <AdminRoute
        exact
        path={ROUTES.MONITORING.AV_MACHINES.LIST}
        component={AVMonitoringList}
        access="access_av_machine_monitoring"
      />
      <AdminRoute
        exact
        path={ROUTES.MONITORING.KEYWORDS}
        component={KeywordClientList}
        access="access_keywords_monitoring"
      />
      <AdminRoute
        exact
        path={ROUTES.MONITORING.REPORTS}
        component={ReportsMonitoringPage}
        access="access_mailer_monitoring"
      />
      <AdminRoute
        exact
        path={ROUTES.MONITORING.ACTIVITY_TRACKER}
        component={ActivityTrackerList}
        access="access_activity_tracker"
      />

      <Route exact path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
