import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IUsersCreateForm,
  IUsersRequestPayload,
  IUsersResponsePayload,
  IUsersUpdateForm,
  IUsersDownloadResponse,
} from 'shared/interfaces/IUsers';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET, POST, DELETE } = useAxios();

export const useUsersDao = () => {
  //
  const getUsers = async (payload?: IUsersRequestPayload) => {
    const transformedPayload = transformPayload<IUsersRequestPayload>(payload);

    const response = await GET<IUsersResponsePayload>({
      url: `${ENDPOINTS.USERS}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };

  const searchUsers = async (payload?: IUsersRequestPayload) => {
    const transformedPayload = transformPayload<IUsersRequestPayload>(payload);

    const response = await GET<IUsersResponsePayload>({
      url: `${ENDPOINTS.USERS}`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const getSingleUser = async (id?: string) => {
    const transformedPayload = transformPayload({ _id: id, limit: 1 });

    const response = await GET<IUsersResponsePayload>({
      url: `${ENDPOINTS.USERS}/show`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const createUser = async (payload?: { data?: IUsersCreateForm }) => {
    const transformedPayload = transformPayload<IUsersCreateForm>(payload?.data);

    const formData = new FormData();
    formData.append('token', transformedPayload);

    const response = await POST({
      url: `${ENDPOINTS.USERS}/create`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const updateUser = async (payload?: { data?: IUsersUpdateForm }) => {
    const transformedPayload = transformPayload<IUsersUpdateForm>(payload?.data);

    const formData = new FormData();
    formData.append('token', transformedPayload);

    const response = await POST({
      url: `${ENDPOINTS.USERS}/update`,
      data: formData,
      // params: {
      //   token: formData.get('token'),
      // },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const deleteUser = async (id: string) => {
    const transformedPayload = transformPayload({ _id: id });

    const response = await DELETE({
      url: `${ENDPOINTS.USERS}/delete`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const get_users_by_client = async (payload?: any) => {
    const transformedPayload = transformPayload<any>(payload);

    const response = await GET<IUsersResponsePayload>({
      url: `${ENDPOINTS.USERS}`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const getUserHashKey = async (payload?: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await GET<IUsersResponsePayload>({
      url: `${ENDPOINTS.USERS}/generate_hash_login`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const getUsersActivities = async (payload?: any) => {
    const transformedPayload = transformPayload<any>(payload);

    const response = await GET<IUsersResponsePayload>({
      url: `${ENDPOINTS.USERS}/activities`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const downloadActivities = async (payload?: any) => {
    const transformedPayload = transformPayload<any>(payload);

    const response = await GET<IUsersDownloadResponse>({
      url: `${ENDPOINTS.USERS}/activities/download`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  //
  return {
    getUsers,
    getSingleUser,
    createUser,
    updateUser,
    deleteUser,
    get_users_by_client,
    getUserHashKey,
    searchUsers,
    getUsersActivities,
    downloadActivities,
  };
};
